<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()"
    [ngClass]="{'conected': isCondensed === true}">
    <div class="container">
        <div>
            <a class="logo" [routerLink]="['', 'home', '']">
                <img src="assets/images/growth/Eyas_logo.svg" height="60" alt="" *ngIf="!isCondensed">
                <img src="assets/images/growth/Eyas_logo-dark.svg" height="60" alt="" *ngIf="isCondensed">
            </a>
        </div>
        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
                    <div class="lines">
                        <span class="line" [ngStyle]="{'background-color': isCondensed ? '#3c4858' : '#fff'}"></span>
                        <span class="line" [ngStyle]="{'background-color': isCondensed ? '#3c4858' : '#fff'}"></span>
                        <span class="line" [ngStyle]="{'background-color': isCondensed ? '#3c4858' : '#fff'}"></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <div id="navigation">
            <!-- Navigation Menu-->
            <ul class="navigation-menu align-items-center justify-content-between ">

                <ul class="navigation-menu align-items-center">
                    <li class="has-submenu" [routerLinkActive]="['active']">
                        <a class="nav-link " [routerLink]="['', 'home', '']">{{"HOME" |translate}}</a>

                    </li>
                    <li class="has-submenu" [routerLinkActive]="['active']">
                        <a class="nav-link" [routerLink]="['', 'About-Eyas']">{{"ABOUT" |translate}}</a>
                    </li>
                    <li class="has-submenu" [routerLinkActive]="['active']">
                        <a class="nav-link" href="https://landing.eyasapp.com/business" target="_blank">{{"BLOG"
                            |translate}}</a>
                    </li>

                    <li class="has-submenu" [routerLinkActive]="['active']">
                        <a class="nav-link" [routerLink]="['', 'blog']" >المدونه</a>
                    </li>

                    <li class="has-submenu" [routerLinkActive]="['active']">
                        <a class="nav-link" style="cursor: pointer" (click)="scrollToBottom()">{{"CONTACT"
                            |translate}}</a>
                    </li>
                    <!-- <li class="mode has-submenu " role="button">
                        <a (click)="switchLanguage()">
                            {{ translate.currentLang === 'en' ? 'AR' : 'EN' }}
                        </a>
                    </li> -->
                </ul>

                <li class="has-submenu">
                    <div class="d-flex user-div" [ngClass]="{'culomn': isCondensed === true}">
                        <button class="send-whats send2" (click)="navigateTo('https://wa.me/966501100712', '_blank')">
                            <img class="img-whats" src="../../../../../assets/images/growth/whatsUp.png" alt="">
                            <span>{{"WHATS" |translate}}</span>
                        </button>
                        <a (click)="navigateTo('/Request', '')" class="send-request">{{"SEND_REQUEST" |translate}}</a>
                        <div class="auth-container">
                            <app-feather-icon [icon]="'user'" class="user-icon"
                                [ngStyle]="{'color': isCondensed ? '#3c4858' : '#fff'}"></app-feather-icon>
                            <span class="auth" *ngIf="!isLoggined">
<!--                                <a [routerLink]="['', 'auth', 'signup']"-->
<!--                                    [ngStyle]="{'color': isCondensed ? '#3c4858' : '#fff'}"-->
<!--                                    [ngClass]="{'link-eyas-gold': !isCondensed}" class="register pe-1">-->
<!--                                    {{ 'REGISTER' | translate }}-->
<!--                                </a>-->
<!--                                /-->
                                <a [routerLink]="['', 'auth', 'login']" [ngClass]="{'link-eyas-gold': !isCondensed}"
                                    [ngStyle]="{'color': isCondensed ? '#3c4858' : '#fff'}" class="ps-1">
                                    {{"LOGIN"|translate}}
                                </a>
                            </span>
                            <span class="auth" *ngIf="isLoggined">
                                <a [routerLink]="['', 'User', 'Consultation-Steps']"
                                    [ngStyle]="{'color': isCondensed ? '#3c4858' : '#fff'}"
                                    [ngClass]="{'link-eyas-gold': !isCondensed}" class="register pe-1">
                                    خطوات أخذ الاستشارة
                                    <!-- {{ 'DASH' | translate }} -->
                                </a>
                                |
                                <a (click)="logout()"
                                    [ngStyle]="{'color': isCondensed ? '#3c4858' : '#fff', 'cursor': 'pointer'}"
                                    [ngClass]="{'link-eyas-gold': !isCondensed}" class="register pe-1">
                                    {{ 'LOGOUT' | translate }}
                                </a>

                            </span>
                        </div>
                    </div>
                </li>
            </ul>

        </div>

    </div>
</header>